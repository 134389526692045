import { BURNT_ORANGE, SUCCESS_GREEN } from "#app/colors/colors";
import FxDialog from "#app/components/FxDialog/FxDialog";
import FxDialogActions from "#app/components/FxDialog/FxDialogActions";
import FxDialogContent from "#app/components/FxDialog/FxDialogContent";
import FxDialogTitle from "#app/components/FxDialog/FxDialogTitle";
import Modal from "#app/components/Modal";
import ModalConfirmation from "#app/components/ModalConfirmation/ModalConfirmation";
import { useCommand } from "#app/services/beta";
import { useSelector } from "#app/state/useSelector";
import { userIsPartner } from '#services/authorization';
import { OrganisationDto } from "@fixrate/fixrate-query";
import { Button, MenuItem, Select, Snackbar, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GenericUser } from "./UsersList";
import styles from './UsersList.module.scss';

type AdvisorChangedMessage = {
    customerId: string,
    message: string
}
export function RemoveUserModal({user, setRemoveUserModalVisible, removeUser, organisation} : {user: GenericUser, setRemoveUserModalVisible: (value: boolean) => void, removeUser: (userId: string) => void, organisation: OrganisationDto}) {
    const { t } = useTranslation()
    const {setPartnerPrimaryAdvisor, addPartnerAdvisor} = useCommand()
    const sessionId = useSelector(state => state.session.id)
    const partner = useSelector(state => state.partner)
    const [loadingAdvisor, setLoadingAdvisor] = useState<string[]>([])
    const isPartnerAdmin = partner?.users?.find(user => user.id === sessionId)?.roles.includes('PARTNER_ADMIN')
    const partnerUserCustomers = partner?.customers?.filter(customer => customer.primaryAdvisor === user.id)
    const isSupport = partner?.partnerCategory === 'SUPPORT'
    const userIsAdvisor = !!user && userIsPartner(user)
    const userIsActiveAuthorizationSigner = useSelector(({depositors, documents}) => !user ? false : depositors.find(d => d.id === organisation.id)?.users.flatMap(u => documents[u.authorizationDocumentId]?.allSignatures.map(s => s.userId)).filter(id => id === user.id).length > 0)
    const userName = user.firstName + ' ' + user.lastName

    const [primaryAdvisorChangedMessages, setPrimaryAdvisorChangedMessages] = useState<AdvisorChangedMessage[]>([])

    const setPrimaryAdvisor = async (depositorId: string, advisorId: string) => {
        const advisorName = partner?.users?.find(u => u.id === advisorId)?.firstName + ' ' + partner?.users?.find(u => u.id === advisorId)?.lastName
        const {waitForCommand} = await setPartnerPrimaryAdvisor(partner.id, depositorId, advisorId)
        const success = await waitForCommand()
        if (success) {
            console.log('Advisor set')
            setPrimaryAdvisorChangedMessages([...primaryAdvisorChangedMessages, {customerId: depositorId, message: `${advisorName} er nå kundeansvarlig for ${partner?.customers.find(c => c.depositorId === depositorId)?.name}`}])
        }
    }

    const addAdvisor = async (depositorId: string, advisorId: string) => {
        const {waitForCommand} = await addPartnerAdvisor(partner.id, depositorId, advisorId)
        const success = await waitForCommand()
        if (success) {
            console.log('Advisor added')
        }
    }

    const handleSelectAdvisor = async (depositorId: string, advisorId: string) => {
        addAdvisor(depositorId, advisorId).then(() => {
            setPrimaryAdvisor(depositorId, advisorId)
        }).catch(() => {
            console.log('Failed to set primary advisor')
        })
    }

    if (isPartnerAdmin) {
        return (
            <>
                <FxDialog open={true} maxWidth="sm" fullWidth onClose={() => setRemoveUserModalVisible(false)}>
                    <FxDialogTitle onClose={() => setRemoveUserModalVisible(false)}>
                        {t('blocks-UsersList.removeUserHeading')}
                    </FxDialogTitle>
                    <FxDialogContent>
                        <Stack spacing={2} sx={{py: 3}}>
                            <Typography>{t('blocks-UsersList.removePartnerAccountant', {name: userName, organisation: organisation?.name})}</Typography>
                            { partnerUserCustomers?.length > 0 ? (
                                <Typography>{t('blocks-UsersList.accountantHasCustomer', {name: userName, numberOfCustomers: partnerUserCustomers?.length})}</Typography>
                            ) : (
                                <Typography variant="body2">{t('blocks-UsersList.accountantHasNoCustomers', {name: userName})}</Typography>
                            )}
                            { partnerUserCustomers?.length > 0 && (
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>{t('pages-customers.customer')}</TableCell>
                                            <TableCell>{t('pages-customers.responsible')}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {partnerUserCustomers?.map(customer => (
                                            <TableRow key={customer.depositorId}>
                                                <TableCell>
                                                    <Stack direction="row" spacing={0.5} alignItems={"center"}>
                                                        <i className="ri-building-line"/>
                                                        <span>{customer.name}</span>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell>
                                                    <Select
                                                        size="small"
                                                        value={customer.primaryAdvisor}
                                                        onChange={(e) => handleSelectAdvisor(customer.depositorId, e.target.value as string)}
                                                    >
                                                        {partner?.users.map(u => (
                                                            <MenuItem 
                                                                key={u.id}
                                                                value={u.id}>
                                                                <Stack direction="row" spacing={0.5}>
                                                                    { customer.primaryAdvisor === u.id && (
                                                                        customer.primaryAdvisor === u.id ? <i className="ri-error-warning-line" style={{color: BURNT_ORANGE[500]}}/> : <i className="ri-check-line" style={{color: SUCCESS_GREEN[500]}}/>
                                                                    )}
                                                                    {u.firstName} {u.lastName}
                                                                </Stack>
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                            { primaryAdvisorChangedMessages.map(message => (
                                <Snackbar
                                    key={message.customerId}
                                    sx={{
                                        "& .MuiSnackbarContent-root": {
                                            backgroundColor: SUCCESS_GREEN[100],
                                            color: SUCCESS_GREEN[900]
                                        }}
                                    }
                                    open={true}
                                    autoHideDuration={6000}
                                    message={
                                        <Stack direction="row" mr={1} spacing={0.5} alignItems={"center"}>
                                            <i style={{fontSize: "2rem"}} className="ri-check-line"/>
                                            <span>{message.message}</span>
                                        </Stack>
                                    }
                                    onClose={() => setPrimaryAdvisorChangedMessages(primaryAdvisorChangedMessages.filter(m => m.customerId !== message.customerId))}
                                />
                            ))}
                        </Stack>
                    </FxDialogContent>
                    <FxDialogActions>
                        <Button onClick={() => setRemoveUserModalVisible(false)} variant="outlined">{t('common.cancel')}</Button>
                        <Button 
                            startIcon={<i className="ri-delete-bin-line"/>} 
                            onClick={() => removeUser(user.id)} 
                            variant="contained">{t('blocks-UsersList.deleteUser')}</Button>
                    </FxDialogActions>
                </FxDialog>
            </>
        )
    }

    if (!userIsAdvisor && isSupport) {
        return (
            <Modal onCancel={() => setRemoveUserModalVisible(false)} width='40rem' header={t('blocks-UsersList.removeUserHeading')} onSubmit={() => removeUser(user.id)} className={styles.confirmation}>
                <div className={styles.innerWrapper}>
                    <p className={styles.body}>{t('blocks-UsersList.removeSupportUserConfirmation', { userName: user.firstName + ' ' + user.lastName, organizationName: organisation.name })}</p>
                </div>
            </Modal>
        )
    }

    if (userIsAdvisor) {
        return (
            <ModalConfirmation
                backUrl={''}
                header={t('blocks-UsersList.removeUserHeading')}
                onSubmit={() => setRemoveUserModalVisible(false)}
                text={t('blocks-UsersList.removeUserContactSupport', { userName: user.firstName + ' ' + user.lastName, organizationName: organisation.name })}
            />
        )
    }

    if (!userIsAdvisor && !isSupport) {
        return (
            <Modal onCancel={() => setRemoveUserModalVisible(false)} width='40rem' header={t('blocks-UsersList.removeUserHeading')} onSubmit={() => removeUser(user.id)} className={styles.confirmation}>
                <div className={styles.innerWrapper}>
                    <p className={styles.body}>
                        {userIsActiveAuthorizationSigner
                            ? t('blocks-UsersList.removeUserIsActiveAuthorizationSigner', { userName: user.firstName + ' ' + user.lastName })
                            : t('blocks-UsersList.removeUserConfirmation', {
                                userName: user.firstName + ' ' + user.lastName,
                                organizationName: organisation.name,
                            })}
                    </p>
                </div>
            </Modal>
        )
    }
}