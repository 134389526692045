import {Animated, PageHeader, PageLayout} from '#app/components';
import useCurrentCountryCode from '#app/services/useCurrentCountryCode';
import {Box, Checkbox, Container, FormControlLabel, Grid, Icon, Paper, Stack, ThemeProvider, Typography} from '@mui/material';
import { isFuture, isPast } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BankMarginSegment } from './components/BankMarginSegment';
import { FullySubscribedAds } from './components/FullySubscribedAds';
import { Header } from './components/Header';
import { LastOrders } from './components/LastOrders';
import { MyAds } from './components/MyAds';
import { VolumePerSegment } from './components/VolumePerSegment';
import Layout from "#pages/Layout";
import {BentoCard} from "#pages/BankDashboard/components/bento/BentoCard";
import {Webinars} from "#pages/BankDashboard/components/Webinars";
import {PURPLE, SPRING_GREEN} from '#app/colors/colors';
import {IconBentoCard} from "#pages/BankDashboard/components/bento/IconBentoCard";

const PRODUCT_31D_ID = '5'

export const BankDashboard = () => {
    const organisationCountry = useCurrentCountryCode()
    const {t} = useTranslation()

    return (
        <Fragment>
            <PageHeader icon="ri-dashboard-line" title="Dashboard"/>
            <PageLayout sx={{px: 0, pt: 2, pb: 0}}>
                <Stack spacing={4}>
                    <Container maxWidth="xl">
                        <Grid container spacing={2} align-items={'flex-start'} p={2}>
                            <BentoCard size={12}>
                                <Header />
                            </BentoCard>
                            <BentoCard noPadding size={12} title={"Annonser"} sx={{gridRowEnd: 'span 5'}}>
                                <MyAds/>
                            </BentoCard>
                            {organisationCountry === "NO" &&
                                <BentoCard size={6} title={"Webinar"}>
                                    <Webinars />
                                </BentoCard>
                            }
                            <IconBentoCard
                                title={t('pages-BankDashboard.download')}
                                content={t('pages-BankDashboard.guidesDescription')}
                                icon={'notesFill'}
                                actions={[
                                    {
                                        text: t('pages-BankDashboard.depositGuide'),
                                        icon: 'ri-file-download-line',
                                        variant: 'text',
                                        onClick: () => { window.open("https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front/dokumentpakke-bank/Veileder-Nytt-Innskudd.pdf", "_blank") }
                                    },
                                    {
                                        text: t('pages-BankDashboard.productTerms'),
                                        icon: 'ri-file-download-line',
                                        variant: 'text',
                                        onClick: () => { window.open("https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front/dokumentpakke-bank/Produktbetingelser-Fixrate-1.pdf", "_blank") }
                                    },
                                    {
                                        text: t('pages-BankDashboard.deviationsGuide'),
                                        icon: 'ri-file-download-line',
                                        variant: 'text',
                                        onClick: () => { window.open("https://fixrate-public.s3.eu-central-1.amazonaws.com/fixrate-front/dokumentpakke-bank/Kilder%20til%20avvik%20%E2%80%93%20En%20veileder%20for%20bank.pdf", "_blank") }
                                    }
                                ]}
                            />
                        </Grid>
                    </Container>
                    {organisationCountry === "NO" && (
                        <Box sx={{p: 0, pb: 2, m: 0, backgroundColor: PURPLE[900]}}>
                            <Container maxWidth="xl">
                                <Box alignItems={'stretch'}>
                                    <Stack direction={'row'} paddingLeft={3} paddingTop={2}>
                                        <Typography variant={'h3'} color={SPRING_GREEN[500]}>
                                            <i className='ri-store-2-line' />
                                            {t('pages-BankDashboard.latestFromTheMarketplace')}
                                        </Typography>
                                    </Stack>
                                    <Grid container spacing={2} align-items={'flex-start'} p={2}>
                                        <BentoCard darkMode size={12} titleIcon={'ri-flashlight-fill'} title={t('pages-BankDashboard.lastOrders')}>
                                            <LastOrders/>
                                        </BentoCard>
                                        <BentoCard darkMode size={12} titleIcon={'ri-check-double-line'} title={t("pages-BankDashboard.lastFullySubscribedAds")}>
                                            <FullySubscribedAds/>
                                        </BentoCard>
                                        <BentoCard darkMode size={6} titleIcon={'ri-bar-chart-grouped-fill'} title={t("pages-BankDashboard.volumeBankSegment")}>
                                            <VolumePerSegment/>
                                        </BentoCard>
                                        <BentoCard darkMode size={6} titleIcon={'ri-line-chart-fill'} title={t('pages-BankDashboard.marginAddition')}>
                                            <BankMarginSegment/>
                                        </BentoCard>
                                    </Grid>
                                </Box>
                            </Container>
                        </Box>
                    )}
                </Stack>
            </PageLayout>
        </Fragment>
    );
}
